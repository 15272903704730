import React from 'react'
import ServicesMain from '../Components/ServicesMain'
import ServicesBenefits from '../Components/ServicesBenefits'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const RegistrationComplinece = () => {
  let obj={
    "Category":"Compliance",
    "firstImagepath":"images/Compliance.png",
    "ServiceHeading":"Expert Registration and Compliance Solutions.",
    "ServiceIntro":"At SSGA & CO,we are dedicated to delivering comprehensive solutions for registration and compliance. With over 10 years of experience, our team ensures that your business meets all regulatory requirements efficiently and effectively."
}
let obj2={
    "KeyFeatureHeading":"Key Benefits of Our Registration and Compliance Services",
    "KeyFeatureFirstHeading":"Experience and Expertise",
    "KeyFeatureFirstinfo":"Our seasoned professionals provide strategic guidance tailored to your business needs, ensuring compliance",
    "KeyFeatureSecondHeading":"Compliance Assurance",
    "KeyFeatureSecondinfo":"We handle all aspects of registration and compliance, ensuring your business remains in good standing with regulatory authorities.",
    "KeyFeatureThirdHeading":"Risk Management",
    "KeyFeatureThirdinfo":"We identify and mitigate compliance risks, protecting your business from potential legal issues and penalties.",
    "KeyFeatureFourthHeading":"Streamlined Processes",
    "KeyFeatureFourthinfo":"Our efficient processes save you time and resources, allowing you to focus on core business activities.",
    "BenefitHeading":"Benefits of Registration and Compliance",
    "FirstBenefit":"Proven Track Record.",
    "SecondBenefit":"Client-Centered Approach.",
    "ThirdBenefit":"Ethical Standards.",
    "FourthBenefit":"Compliance Assurance.",
    "secondImagepath":"images/Compliance2.jpg"
}
return (
<div>
    <Header/>
    <ServicesMain infoMain={obj} />
    <ServicesBenefits infoBenefits={obj2} />
    <ScrollToTop/>
    <Footer/>
</div>
)

}

export default RegistrationComplinece