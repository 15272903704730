import React from 'react'

const HomeTeam = () => {
    return (
        <div>
            <section>
                <div className="container">
                    <div className="row justify-content-center ">
                        <div className="col-xl-5 col-lg-6 col-sm-8 text-center" >
                            <span className="bg-solitude-blue text-uppercase fs-13 ps-25px pe-25px alt-font fw-600 text-base-color lh-40 sm-lh-55 border-radius-100px d-inline-block mb-25px">Creative
                                team</span>
                            <h3 className="alt-font text-dark-gray fw-600 ls-minus-2px">Committed staff are ready to help you</h3>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center" >
                        
                        
                        <div className=" col text-center team-style-05 md-mb-35px xs-mb-35px">
                            <div className="position-relative mb-30px last-paragraph-no-margin">
                                <img className="border-radius-4px"   style={{ display: 'none', visibility: 'hidden' }} /> <img src="../images/Team-img (3).png" className="border-radius-4px" 
                                 />
                                <div className="w-100 h-100 d-flex flex-column justify-content-end align-items-center border-radius-4px p-40px team-content bg-base-color-transparent">
                                    <p className="text-white w-80 lg-w-75 sm-w-80 absolute-middle-center opacity-7">The Managing partner of the Delhi Branch of the SSGA &amp; Co.</p>
                                </div>
                            </div>
                            <div className="alt-font fw-500 text-dark-gray lh-22 fs-18">Mr. Shanky Mangla</div>
                            <span>Chartered Accountant &amp; Partner</span>
                        </div>
                        <div className=" col text-center team-style-05 md-mb-35px xs-mb-35px ">
                            <div className="position-relative mb-30px last-paragraph-no-margin">
                                <img className="border-radius-4px"   style={{ display: 'none', visibility: 'hidden' }} /> <img src="../images/Team-img (4).png" className="border-radius-4px"  />
                                <div className="w-100 h-100 d-flex flex-column justify-content-end align-items-center border-radius-4px p-40px team-content bg-base-color-transparent">
                                    <p className="text-white w-80 lg-w-75 sm-w-80 absolute-middle-center opacity-7">Sahil Goyal is a partner of the Delhi Branch of the SSGA &amp; Co</p>
                                </div>
                            </div>
                            <div className="alt-font fw-500 text-dark-gray lh-22 fs-18">Mr. Sahil Goyal</div>
                            <span>Chartered Accountant &amp; Partner</span>
                        </div>
                        
                        <div className=" col text-center team-style-05 md-mb-35px xs-mb-35px">
                            <div className="position-relative mb-30px last-paragraph-no-margin">
                                <img className="border-radius-4px"   style={{ display: 'none', visibility: 'hidden' }} /> <img src="../images/Team-img (6).png" className="border-radius-4px"  />
                                <div className="w-100 h-100 d-flex flex-column justify-content-end align-items-center border-radius-4px p-40px team-content bg-base-color-transparent">
                                    <p className="text-white w-80 lg-w-75 sm-w-80 absolute-middle-center opacity-7">Sunny Garg is a partner of the Delhi Branch of the SSGA &amp; Co.</p>
                                </div>
                            </div>
                            <div className="alt-font fw-500 text-dark-gray lh-22 fs-18">Mr. Sunny Garg</div>
                            <span>Chartered Accountant &amp; Partner</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomeTeam