import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import feather from 'feather-icons';
import {Link} from 'react-router-dom'
const HomeServices = () => {
    useEffect(() => {
        AOS.init({
          duration: 600,
          easing: 'easeOutQuad',
          once: true,
        });
        feather.replace(); // Replace feather icons
      }, []);
    const services = [
        {
          title: 'Transfer Pricing',
          description: 'It refers to the rules & methods for pricing transactions between enterprises under common ownership or control.',
          imgSrc: '../images/Transfer.png',
          link: '/TransferPricing',
        },
        {
          title: 'Tax Litigation',
          description: 'We also assist clients with disputes and litigation related to tax issues, providing expert guidance and support.',
          imgSrc: '../images/Tax litigation.jpg',
          link: '/Taxlitigation',
        },
        {
          title: 'Registration and Compliances',
          description: 'The processes & requirements that Businesses must adhere to in order to operate legally & smoothly within a framework.',
          imgSrc: '../images/Service4.jpg',
          link: '/RegistrationComplinece',
        },
        {
          title: 'Start Up\'s',
          description: 'We provide Guidance and assistance with choosing the right business structure based on the startup\'s goals and regulatory requirements.',
          imgSrc: '../images/Service5.jpg',
          link: '/Startup',
        },
        {
          title: 'Trademark',
          description: 'We offer Trademark facility which is an indicator used by Businesses to distinguish their products & services from others in the marketplace.',
          imgSrc: '../images/Trade.jpg',
          link: '/TradeMark',
        },
        {
          title: 'Income Tax',
          description: 'We help in reducing the Tax imposed by governments on individuals & businesses based on their income & profits earned during a taxable period.',
          imgSrc: '../images/Income.jpg',
          link: '/IncomeTax',
        },
      ];
    return (
        <div>
             <section className="bg-solitude-blue">
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="col-xl-7 col-lg-9 col-md-10 text-center">
            <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px"
                data-aos='fade-up'
                data-aos-delay='0'
                data-aos-staggervalue='300'>
              <span> Professional & Highly Comprehensive </span> Accounting services
            </h3>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center"
             data-aos="fade-up"
             data-aos-delay="0"
             data-aos-staggervalue="300">
          {services.map((service, index) => (
            <div className="col" key={index}>
              <div className="box-shadow-quadruple-large services-box-style-01 hover-box mb-30px border-radius-5px overflow-hidden">
                <div className="position-relative box-image">
                  <img style={{ height: '300px' }} src={service.imgSrc}  />
                  <div className="box-overlay bg-dark-gray"></div>
                  <Link to={service.link}
                     className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white">
                    <i className="feather icon-feather-arrow-right text-white icon-extra-medium"></i>
                  </Link>
                </div>
                <div className="p-10 bg-white last-paragraph-no-margin text-center">
                  <a href={service.link}
                     className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">{service.title}</a>
                  <p style={{ textAlign: 'justify' }}>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
        </div>

    )
}

export default HomeServices