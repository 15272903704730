import React from 'react'
import ContactBgbanner from '../Components/ContactBgbanner'
import ContactForm from '../Components/ContactForm'
import Location from '../Components/Location'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const Contact = () => {
  return (
    <div>
        <Header/>
        <ContactBgbanner/>
        <ContactForm/>
        <Location/>
        <ScrollToTop/>
        <Footer/>
    </div>
  )
}

export default Contact