import React from 'react'

const Location = () => {
  return (
    <div>
  <section className="p-0" id="location">
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 p-0">
          <div id="map" className="map">
          
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2810.4407009865777!2d77.14659178025089!3d28.69391608327309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03ccd37eba13%3A0x64d4608315a82b4f!2sPP%20Trade%20Centre%2C%20Netaji%20Subhash%20Place%2C%20Shakurpur%2C%20Delhi%2C%20110034!5e0!3m2!1sen!2sin!4v1721418053796!5m2!1sen!2sin" width="100%" height="100%" style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  )
}

export default Location