import React from 'react'
import { Link } from 'react-router-dom'

const AboutusServices = () => {
  return (
    <div>
        <section className="py-0">
  <div className="container">
    <div className="row justify-content-center mb-4">
      <div className="col-xl-7 col-lg-9 col-md-10 text-center">
        <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px" >
          Other business services</h3>
      </div>
    </div>
    <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center" >
      <div className="col">
        <div className="box-shadow-quadruple-large services-box-style-01 hover-box border-radius-5px overflow-hidden md-mb-30px">
          <div className="position-relative box-image">
            <img style={{height: 300}} src="../images/Service5.jpg"  />
            <div className="box-overlay bg-dark-gray" />
            <Link to={'/Startup'}  className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
          </div>
          <div className="p-10 bg-white last-paragraph-no-margin text-center">
            <Link to={'/Startup'} className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Start
              Up's</Link>
            <p style={{textAlign: 'justify'}}>We provide Guidance and assistance with choosing the right
              business structure based on the startup's goals and regulatory&nbsp;requirements.</p>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="box-shadow-quadruple-large services-box-style-01 hover-box border-radius-5px overflow-hidden sm-mb-30px">
          <div className="position-relative box-image">
            <img style={{height: 300}} src="../images/Trade.jpg"  />
            <div className="box-overlay bg-dark-gray" />
            <Link to={'/TradeMark'} className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
          </div>
          <div className="p-10 bg-white last-paragraph-no-margin text-center">
            <Link to={'/TradeMark'} className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Trademark</Link>
            <p style={{textAlign: 'justify'}}>We offers Trademark facility which is a indicator used by
              Businesses to distinguish their products &amp; services from others in the&nbsp;marketplace.</p>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="box-shadow-quadruple-large services-box-style-01 hover-box border-radius-5px overflow-hidden">
          <div className="position-relative box-image">
            <img style={{height: 300}} src="../images/Income.jpg"  />
            <div className="box-overlay bg-dark-gray" />
            <Link to={'/IncomeTax'}  className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
          </div>
          <div className="p-10 bg-white last-paragraph-no-margin text-center">
            <Link to={'/IncomeTax'} className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Income
              Tax</Link>
            <p>We help in reducing the Tax imposed by govts on individuals &amp; businesses based on their
              income &amp; profits earned during a&nbsp;taxable&nbsp;period.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



  <section className="half-section">
  <div className="container">
    <div className="row align-items-center text-center text-md-start">
      <div className="col sm-mb-20px" >
        <h4 className="alt-font text-dark-gray fw-600 ls-minus-2px m-0">Get a free personal business
          consultation?</h4>
      </div>
      <div className="col-12 col-md-auto" >
        <Link to={'/Contact'} className="btn btn-large btn-dark-gray btn-box-shadow btn-rounded left-icon"><i className="feather icon-feather-mail" />Reach Us</Link>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default AboutusServices