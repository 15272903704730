import React from 'react'
import AboutusBanner from '../Components/AboutusBanner'
import AboutusComponent from '../Components/AboutusComponent'
import Header from '../Components/Header'
import AboutusBenefits from '../Components/AboutusBenefits'
import AboutusServices from '../Components/AboutusServices'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const Aboutus = () => {
  return (
    <div>
      <Header/>
      <AboutusBanner/>
      <AboutusComponent/> 
    <AboutusBenefits/>
    <AboutusServices/>
    <ScrollToTop/>
    <Footer/>
    </div>
  )
}

export default Aboutus