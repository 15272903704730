import React from 'react'
import vediosrc from '../vedio/Services.mp4'
const ServicesVedioBanner = () => {
  return (
    <div>
    <section className="page-title-big-typography bg-dark-gray ipad-top-space-margin" data-parallax-background-ratio="0.5">
  <div className="opacity-extra-medium bg-dark-slate-blue">
    <video height="100%" width="100%" className="main" autoPlay loop muted>
      <source src={vediosrc} type="video/mp4" />
    </video>
  </div>
  <div className="container">
    <div className="row align-items-center justify-content-center extra-small-screen">
      <div className="col-12 position-relative text-center page-title-extra-large">
        <h1 className="m-auto text-white text-shadow-double-large fw-500 ls-minus-3px xs-ls-minus-2px arimo-bold" >
          Services </h1>
      </div>
      <div className="down-section text-center" >
        <a href="#down-section" aria-label="scroll down" className="section-link">
          <div className="d-flex justify-content-center align-items-center mx-auto rounded-circle fs-30 text-white">
            <i className="feather icon-feather-chevron-down" />
          </div>
        </a>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default ServicesVedioBanner