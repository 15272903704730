import React, { useEffect } from 'react'
import Atropos from 'atropos/react';
import anime from 'animejs';
import 'atropos/css';
import { Link } from 'react-router-dom';
const AboutusComponent = () => {
    useEffect(() => {
        // Initialize anime.js animations
        anime({
          targets: '[data-anime]',
          translateY: (el) => el.getAttribute('data-anime-translateY').split(',').map(Number),
          opacity: (el) => el.getAttribute('data-anime-opacity').split(',').map(Number),
          duration: (el) => Number(el.getAttribute('data-anime-duration')),
          delay: (el) => Number(el.getAttribute('data-anime-delay')),
          easing: 'easeOutQuad',
        });
      }, []);
    
      return (
        <div>
        <section id="down-section">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-5 col-md-10 position-relative z-index-1 md-mb-40px">
                <Atropos
                  activeOffset={40}
                  shadowScale={1.05}
                  highlight={false}
                >
                  <img src="../images/AboutusAccounting.jpg" className="border-radius-6px w-100"  />
                </Atropos>
              </div>
              <div className="col-xl-6 col-lg-6 offset-lg-1 col-md-9 ps-6 text-center text-lg-start lg-ps-15px"
                   
                   data-anime-opacity="0, 1"
                   data-anime-duration="600"
                   data-anime-delay="0"
                   data-anime-staggervalue="300">
                <span className="bg-solitude-blue text-uppercase fs-13 ps-25px pe-25px alt-font fw-600 text-base-color lh-40 sm-lh-55 border-radius-100px d-inline-block mb-25px">About us</span>
                <h3 className="fw-600 text-dark-gray ls-minus-2px alt-font sm-w-80 xs-w-100 mx-auto sm-mb-20px">Provide advanced business solutions.</h3>
                <p>SSGA & CO, Chartered Accountants, was established in the year 1993 by Mr. Gopal Rawat, Chartered Accountant. He, along with his team of young and energetic professionals through their unrelenting quest for excellence have established their presence in the professional field related to Accounting, Finance, and Audit.</p>
                <p>During the last two and a half decades of its existence, it has grown into a multi-faceted medium-sized Chartered Accountancy firm with six partners, offering a broad spectrum of services to its diverse clientele.</p>
                <Link to={'/Services'} className="btn btn-large btn-dark-gray btn-box-shadow btn-rounded mt-15px sm-mt-10px">Our services<i className="fa-solid fa-arrow-right"></i></Link>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mt-7 ps-5 sm-mt-35px lg-ps-0 pe-5 lg-pe-0">
              <div className="col-md-6 col-sm-8 md-mb-35px">
                <div className="row align-items-center justify-content-center"
                     
                     data-anime-opacity="0, 1"
                     data-anime-duration="800"
                     data-anime-delay="0"
                     data-anime-staggervalue="100">
                  <div className="col-auto"><span className="alt-font text-dark-gray fw-600 fs-130 lg-fs-110 ls-minus-7px">8</span></div>
                  <div className="col-5 col-xl-4 col-lg-5 col-sm-6">
                    <h6 className="fw-500 text-dark-gray alt-font m-0 ls-minus-1px">Years experience working.</h6>
                  </div>
                  <div className="col-3 col-xl-3 col-md-2 text-center d-none d-lg-inline-block"><span className="fw-400 fs-110 text-base-color position-relative top-minus-10px">|</span></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-10 overflow-hidden">
                <div className="row align-items-center"
                     
                     data-anime-opacity="0, 1"
                     data-anime-duration="800"
                     data-anime-delay="200"
                     data-anime-staggervalue="100">
                  <div className="col-sm-6 last-paragraph-no-margin text-center xs-mb-35px">
                    <h2 className="fw-700 text-dark-gray mb-5px alt-font">500 +</h2>
                    <span className="fs-18 alt-font ls-minus-05px fw-500 text-dark-gray">Satisfied customer</span>
                  </div>
                  <div className="col-sm-6 last-paragraph-no-margin text-center">
                    <h2 className="fw-700 text-dark-gray mb-5px alt-font">2+</h2>
                    <span className="fs-18 alt-font ls-minus-05px fw-500 text-dark-gray">Offices</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
         
       </div>
        )
}

export default AboutusComponent