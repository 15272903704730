import React, { useState } from 'react'
import Atropos from 'atropos/react';
// import anime from 'animejs';
import 'atropos/css';
import HomeTeam from './HomeTeam';
const AboutusBenefits = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const accordionData = [
        {
            question: 'What services does SSGA & CO. offer?',
            answer: 'We offer a wide range of services including business planning, financial forecasting, risk management, tax advisory, auditing, and consultancy on business expansion and diversification. Our goal is to provide comprehensive support to meet all your accounting and business needs.'
        },
        {
            question: 'How can SSGA & CO. help my start-up business?',
            answer: 'We assist start-ups by developing clear, achievable goals, offering guidance on business structure and compliance, and providing resources for sustainable growth. Our team ensures that your business is set up for success from the very beginning.'
        },
        {
            question: 'What are the benefits of your business planning services?',
            answer: 'Our business planning services offer several benefits, including enhanced decision-making with detailed financial and strategic insights, improved chances of securing funding, and a clear roadmap for your business’s future. We help you navigate the complexities of business planning to achieve your goals.'
        }
    ];

    return (
        <div>
            <section>
                <div className="container">
                    <div className="row align-items-center justify-content-center mb-7 sm-mb-40px">
                        <div className="col-xl-5 col-lg-6 col-md-10 md-mb-50px">
                            <span className="bg-solitude-blue text-uppercase fs-13 ps-25px pe-25px alt-font fw-600 text-base-color lh-40 sm-lh-55 border-radius-100px d-inline-block mb-25px">Benefits</span>
                            <h3 className="fw-600 text-dark-gray ls-minus-2px alt-font" >
                                Comprehensive range of services to meet your business needs</h3>
                            <div className="icon-with-text-style-08 mb-10px">
                                <div className="feature-box feature-box-left-icon-middle overflow-hidden">
                                    <div className="feature-box-icon feature-box-icon-rounded w-35px h-35px bg-solitude-blue rounded-circle me-10px">
                                        <i className="fa-solid fa-check fs-14 text-base-color" />
                                    </div>
                                    <div className="feature-box-content">
                                        <span className="text-dark-gray">Business Planning Services.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="icon-with-text-style-08 mb-10px">
                                <div className="feature-box feature-box-left-icon-middle overflow-hidden">
                                    <div className="feature-box-icon feature-box-icon-rounded w-35px h-35px bg-solitude-blue rounded-circle me-10px">
                                        <i className="fa-solid fa-check fs-14 text-base-color" />
                                    </div>
                                    <div className="feature-box-content">
                                        <span className="text-dark-gray">Expansion Opportunities.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="icon-with-text-style-08 mb-10px">
                                <div className="feature-box feature-box-left-icon-middle overflow-hidden">
                                    <div className="feature-box-icon feature-box-icon-rounded w-35px h-35px bg-solitude-blue rounded-circle me-10px">
                                        <i className="fa-solid fa-check fs-14 text-base-color" />
                                    </div>
                                    <div className="feature-box-content">
                                        <span className="text-dark-gray">Diversification of Services and New Products.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="icon-with-text-style-08">
                                <div className="feature-box feature-box-left-icon-middle overflow-hidden">
                                    <div className="feature-box-icon feature-box-icon-rounded w-35px h-35px bg-solitude-blue rounded-circle me-10px">
                                        <i className="fa-solid fa-check fs-14 text-base-color" />
                                    </div>
                                    <div className="feature-box-content">
                                        <span className="text-dark-gray">Start-Up Business Development.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-xl-1 position-relative z-index-1" >

                            <Atropos
                                activeOffset={40}
                                shadowScale={1.05}
                                highlight={false}
                            >
                                <img  className="border-radius-6px" style={{ display: 'none', visibility: 'hidden' }} /> <img src="../images/Accounting.jpg"  className="border-radius-6px" />
                            </Atropos>

                        </div>
                    </div>
                    <HomeTeam/>
                    <div className="row align-items-center mt-8 sm-mt-40px">
                        <div className="col-12">
                            <div className="bg-solitude-blue p-9 md-p-6 xs-p-9 border-radius-6px overflow-hidden position-relative">
                                <div className="position-absolute right-70px lg-right-20px top-minus-20px w-250px sm-w-180px xs-w-150px opacity-1">
                                    <img src="../images/demo-business-services-details-faq-icon.png"  />
                                </div>
                                <div className="bg-base-color d-inline-block mb-20px fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">
                                    Basic information
                                </div>
                                <h3 className="alt-font fw-600 text-dark-gray ls-minus-1px">Frequently asked questions</h3>
                                <div className="accordion accordion-style-02" id="accordion-style-02">
                                    {accordionData.map((item, index) => (
                                        <div key={index} className="accordion-item">
                                            <div
                                                className={`accordion-header border-bottom border-color-transparent-dark-very-light ${openIndex === index ? 'active-accordion' : ''}`}
                                                onClick={() => handleToggle(index)}
                                            >
                                                <div className="accordion-title mb-0 position-relative text-dark-gray pe-30px">
                                                    <i className={`feather ${openIndex === index ? 'icon-feather-minus' : 'icon-feather-plus'} fs-20`} />
                                                    <span className="fw-500">{item.question}</span>
                                                </div>
                                            </div>
                                            <div
                                                id={`accordion-style-02-${index}`}
                                                className={`accordion-collapse collapse ${openIndex === index ? 'show' : ''}`}
                                            >
                                                <div className="accordion-body last-paragraph-no-margin border-bottom border-color-transparent-dark-very-light">
                                                    <p className="w-90 sm-w-95 xs-w-100">{item.answer}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default AboutusBenefits