import React, { useEffect, useState } from 'react'

const Homebanner = () => {

    return (
        <div>
            <section id="down-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center mt-7 ps-5 sm-mt-35px lg-ps-0 pe-5 lg-pe-0">
                        <div className="col-md-6 col-sm-8 md-mb-35px">
                            <div className="row align-items-center justify-content-center" >
                                <div className="col-auto"><span className="alt-font text-dark-gray fw-600 fs-120 lg-fs-110 ls-minus-7px">8+</span></div>
                                <div className="col-5 col-xl-4 col-lg-5 col-sm-6">
                                    <h6 className="fw-500 text-dark-gray alt-font m-0 ls-minus-2px ">Years experience working.</h6>
                                </div>
                                <div className="col-3 col-xl-3 col-md-2 text-center d-none d-lg-inline-block"><span className="fw-400 fs-110 text-base-color position-relative top-minus-10px">|</span></div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10 overflow-hidden">
                            <div className="row align-items-center" >
                                <div className="col-sm-6 last-paragraph-no-margin text-center xs-mb-35px">
                                    <h2 className="fw-700 text-dark-gray mb-5px alt-font">500+</h2>
                                    <span className="fs-18 alt-font ls-minus-05px fw-500 text-dark-gray">Satisfied Clients</span>
                                </div>
                                <div className="col-sm-6 last-paragraph-no-margin text-center">
                                    <h2 className="fw-700 text-dark-gray mb-5px alt-font">3+</h2>
                                    <span className="fs-18 alt-font ls-minus-05px fw-500 text-dark-gray">Offices</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="overflow-hidden">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="border-radius-6px h-500px md-h-450px sm-h-350px d-flex align-items-end justify-content-center overflow-hidden cover-background skrollr-zoom pb-9 xs-pb-12"
                 style={{ backgroundImage: "url('../images/Suggest.jpg')", backgroundPosition: "center" }}>
              <div className="opacity-medium bg-gradient-dark-transparent"></div>
              <div className="row justify-content-center">
                <div className="col-11 col-md-7 position-relative z-index-1 text-center text-lg-start md-mb-35px sm-mb-25px">
                  <h4 className="alt-font text-white mb-0 fw-300 fancy-text-style-4">
                    We make the Best solutions for
                    <span className="fw-600">
                    <span className='m-1' >Business!</span>
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        </div>

    )
}

export default Homebanner