import React from 'react'
import Header from '../Components/Header'
import Homevideo from '../Components/Homevideo'
import HomeAbout from '../Components/HomeAbout'
import HomeServices from '../Components/HomeServices'
import Homebanner from '../Components/Homebanner'
import HomeTeam from '../Components/HomeTeam'
import HomeReviews from '../Components/HomeReviews'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const Home = () => {
  return (
    <div>
        <Header/>
        <Homevideo/>
        <HomeAbout/>
        <HomeServices/>
        <Homebanner/>
        <HomeTeam/>
        <HomeReviews/>
        <ScrollToTop/>
        <Footer/>
    </div>
  )
}

export default Home