import React, { useEffect } from 'react'
import CountUp from 'react-countup';

import { Doughnut } from 'react-chartjs-2';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'chart.js/auto';
const HomeAbout = () => {
    useEffect(() => {
        AOS.init({
            duration: 600,
            easing: 'easeOutQuad',
            once: true,
        });
        // Refresh animations when component is mounted or updated
        AOS.refresh();
    }, []);
    const data = {
        datasets: [
          {
            data: [95, 5],
            backgroundColor: ['#5758D6', '#ededed'],
            borderWidth: 0,
          },
        ],
      };
    
      const options = {
        cutout: '75%',
        responsive: true,
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
      };
    return (
        <div>
            <section className="Main-custom border-bottom border-color-extra-medium-gray  overflow-hidden">
                <div className="container">
                    <div className="  row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center align-items-center">
                        <div className="col icon-with-text-style-08 md-mb-30px text-center text-sm-start">
                            <div className="feature-box feature-box-left-icon-middle d-inline-flex align-middle">
                                <div className="feature-box-icon me-10px">
                                    <i className="bi bi-shield-check icon-very-medium text-base-color" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-500 text-dark-gray d-block lh-26">World-class services</span>
                                </div>
                            </div>
                        </div>
                        <div className="col icon-with-text-style-08 md-mb-30px text-center text-sm-start">
                            <div className="feature-box feature-box-left-icon-middle d-inline-flex align-middle">
                                <div className="feature-box-icon me-10px">
                                    <i className="bi bi-hourglass icon-very-medium text-base-color" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-500 text-dark-gray d-block lh-26">Experience strategy</span>
                                </div>
                            </div>
                        </div>
                        <div className="col icon-with-text-style-08 xs-mb-30px text-center text-sm-start">
                            <div className="feature-box feature-box-left-icon-middle d-inline-flex align-middle">
                                <div className="feature-box-icon me-10px">
                                    <i className="bi bi-award icon-very-medium text-base-color" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-500 text-dark-gray d-block lh-26">2+ Offices</span>
                                </div>
                            </div>
                        </div>
                        <div className="col icon-with-text-style-08 text-center text-sm-start">
                            <div className="feature-box feature-box-left-icon-middle d-inline-flex align-middle">
                                <div className="feature-box-icon me-10px">
                                    <i className="bi bi-briefcase icon-very-medium text-base-color" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-500 text-dark-gray d-block lh-26">8+ Years Experience</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="pb-8 md-pb-17 xs-pb-28">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-9 md-mb-50px text-center text-lg-start" data-aos="fade-up"
            data-aos-delay="0">
                            <span className="bg-solitude-blue text-uppercase fs-13 ps-25px pe-25px alt-font fw-600 text-base-color lh-40 sm-lh-55 border-radius-100px d-inline-block mb-25px">About
                                us</span>
                            <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px mb-20px sm-w-85 xs-w-100 mx-auto">Business
                                Advisory and Comprehensive Accounting Services.</h3>
                            <p>We are dedicated to delivering exceptional service and building long-lasting relationships with
                                our clients. We strive to be your trusted financial partner, providing clarity and confidence in
                                your financial decisions.</p>
                            <div className="d-flex flex-row justify-content-center justify-content-lg-start align-items-center mt-35px">
                                <div className="w-120px me-25px flex-shrink-0">
                                    <div className="chart-percent">
                                    <span className="pie-chart-style-01 d-flex align-items-center justify-content-center text-center">
                                   <CountUp
                                                    className="percent d-flex align-items-center justify-content-center alt-font fs-26 text-dark-gray fw-600 ls-minus-1px"
                                            end={95}
                                            duration={2.5}
                                    />
                  </span>
                                    </div>
                                </div>
                                <div className="text-start">
                                    <span className="fs-18 lh-28 text-dark-gray alt-font fw-500 d-inline-block w-80 xs-w-100">Customers
                                        are satisfied with <span className=" fw-600 text-base-color">31+</span> years of
                                        experience.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 offset-xl-1 position-relative">
                            <div className="text-end w-80 md-w-75 ms-auto" data-animation-delay={500} data-shadow-animation="true" data-bottom-top="transform: translateY(50px)" data-top-bottom="transform: translateY(-50px)">
                                <img src="../images/Accounting2.png" alt className="border-radius-5px" />
                            </div>
                            <div className="w-60 md-w-50 xs-w-55 overflow-hidden position-absolute left-15px bottom-minus-50px" data-shadow-animation="true" data-animation-delay={500} data-bottom-top="transform: translateY(-50px)" data-top-bottom="transform: translateY(50px)">
                                <img src="../images/BusinessAdvisory.jpg" alt className="border-radius-5px box-shadow-quadruple-large" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="pb-8 md-pb-17 xs-pb-28">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div
                            className="col-xl-5 col-lg-6 col-md-9 md-mb-50px text-center text-lg-start"
                            data-aos="fade-up"
                            data-aos-delay="0"
                        >
                            <span className="bg-solitude-blue text-uppercase fs-13 ps-25px pe-25px alt-font fw-600 text-base-color lh-40 sm-lh-55 border-radius-100px d-inline-block mb-25px">
                                About us
                            </span>
                            <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px mb-20px sm-w-85 xs-w-100 mx-auto">
                                Business Advisory and Comprehensive Accounting Services.
                            </h3>
                            <p>
                                We are dedicated to delivering exceptional service and building long-lasting relationships with
                                our clients. We strive to be your trusted financial partner, providing clarity and confidence in
                                your financial decisions.
                            </p>
                            <div className="d-flex flex-row justify-content-center justify-content-lg-start align-items-center mt-35px">
                                <div className="w-120px me-25px flex-shrink-0">
                                <div className="chart-percent" data-aos="fade-up">
      <div className="pie-chart-style-01 d-flex align-items-center justify-content-center text-center">
        <Doughnut data={data} options={options} width={120} height={120} />
        <span className="percent d-flex align-items-center justify-content-center alt-font fs-26 text-dark-gray fw-600 ls-minus-1px">
        <CountUp
                      className="percent d-flex align-items-center justify-content-center alt-font fs-26 text-dark-gray fw-600 ls-minus-1px"
                      end={95}
                      duration={2.5}
                    />
        </span>
      </div>
    </div>

                                </div>
                                <div className="text-start">
                                    <span className="fs-18 lh-28 text-dark-gray alt-font fw-500 d-inline-block w-80 xs-w-100">
                                        Customers are satisfied with <span className="fw-600 text-base-color">8+</span> years of
                                        experience.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 offset-xl-1 position-relative">
                            <div
                                className="text-end w-80 md-w-75 ms-auto"

                            >
                                <img src="../../images/Accounting2.png" alt="Accounting" className="border-radius-5px" />
                            </div>
                            <div
                                className="w-60 md-w-50 xs-w-55 overflow-hidden position-absolute left-15px bottom-minus-50px"

                            >
                                <img
                                    src="../../images/BusinessAdvisory.jpg"
                                    alt="Business Advisory"
                                    className="border-radius-5px box-shadow-quadruple-large"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default HomeAbout