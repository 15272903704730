import React from 'react'
import ServicesMain from '../Components/ServicesMain'
import ServicesBenefits from '../Components/ServicesBenefits'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const TradeMark = () => {
  let obj={
    "Category":"Trademark",
    "firstImagepath":"images/TradeMark.png",
    "ServiceHeading":"Expert Trademark Solutions.",
    "ServiceIntro":"At SSGA & CO,we understand the importance of protecting your brand. Our comprehensive trademark services are designed to help you safeguard your intellectual property and build a strong brand identity. With over 31 years of experience, our team of experts ensures that your trademarks are registered, maintained, and defended effectively."
}
let obj2={
    "KeyFeatureHeading":"Key Benefits of Our Trademark Services",
    "KeyFeatureFirstHeading":"Trademark Search and Analysis",
    "KeyFeatureFirstinfo":"We conduct thorough trademark searches to ensure that your proposed trademark is unique and does not infringe on existing trademarks.",
    "KeyFeatureSecondHeading":"Trademark Registration",
    "KeyFeatureSecondinfo":"Our team assists you in the entire trademark registration process, from preparing and filing applications to responding to any objections or oppositions.",
    "KeyFeatureThirdHeading":"Trademark Maintenance",
    "KeyFeatureThirdinfo":"We provide ongoing support to maintain your trademarks, including monitoring renewal deadlines, filing necessary maintenance documents.",
    "KeyFeatureFourthHeading":"International Trademark Protection",
    "KeyFeatureFourthinfo":"If you operate globally, we assist in securing trademark protection in multiple jurisdictions, navigating the complexities of international trademark laws.",
    "BenefitHeading":"Benefits of Trademark",
    "FirstBenefit":"Professional Guidance.",
    "SecondBenefit":"Brand Protection.",
    "ThirdBenefit":"Legal Compliance.",
    "FourthBenefit":"Asset Value.",
    "secondImagepath":"images/TradeMark2.jpg"
}
return (
<div>
    <Header/>
    <ServicesMain infoMain={obj} />
    <ServicesBenefits infoBenefits={obj2} />
    <ScrollToTop/>
    <Footer/>
</div>
)

}

export default TradeMark