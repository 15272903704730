import React from 'react'
import ServicesMain from '../Components/ServicesMain'
import ServicesBenefits from '../Components/ServicesBenefits'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const Startup = () => {
    let obj={
        "Category":"Start Up",
        "firstImagepath":"images/Startup.png",
        "ServiceHeading":"Expert startup solutions.",
        "ServiceIntro":"At SSGA & CO,we are passionate about empowering startups with comprehensive financial and advisory services. With over 10 years of experience, our dedicated team of Chartered Accountants ensures that your startup is well-positioned for success from the ground up."
    }
    let obj2={
        "KeyFeatureHeading":"Key Benefits of Our Startup Services",
        "KeyFeatureFirstHeading":"Business Formation and Structuring",
        "KeyFeatureFirstinfo":"We assist in choosing the right business structure (e.g., sole proprietorship, partnership, corporation) to align with your startup’s goals.",
        "KeyFeatureSecondHeading":"Tax Advisory and Compliance",
        "KeyFeatureSecondinfo":"We offer specialized tax advisory services to optimize tax efficiencies, claim relevant tax credits, and ensure compliance with all tax regulations.",
        "KeyFeatureThirdHeading":"Growth and Scaling",
        "KeyFeatureThirdinfo":"As your startup grows, we provide ongoing support to manage scaling challenges, including financial management, compliance, and strategic planning.",
        "KeyFeatureFourthHeading":"Fundraising Support",
        "KeyFeatureFourthinfo":"We guide you through the fundraising process, including preparing financial statements, business plans, and investor presentations to attract potential investors.",
        "BenefitHeading":"Benefits of Start Up Solutions.",
        "FirstBenefit":"Tailored Expertise.",
        "SecondBenefit":"Financial Clarity.",
        "ThirdBenefit":"Resource Optimization.",
        "FourthBenefit":"Investor Confidence.",
        "secondImagepath":"images/Startup2.jpg"
    }
    return (
    <div>
        <Header/>
        <ServicesMain infoMain={obj} />
        <ServicesBenefits infoBenefits={obj2} />
        <ScrollToTop/>
        <Footer/>
    </div>
    )
    
}

export default Startup