import React from 'react'
import ServicesMain from '../Components/ServicesMain'
import ServicesBenefits from '../Components/ServicesBenefits'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const TransfePricing = () => {
  let obj={
    "Category":"Transfer Pricing",
    "firstImagepath":"images/TransferPricing.png",
    "ServiceHeading":"Expert Transfer Pricing Solutions.",
    "ServiceIntro":"We are passionate about our work and the positive impact it has on our clients. With over 31 years of experience, we consistently deliver excellent solutions in transfer pricing. Our dedicated team ensures compliance with global regulations, optimizes tax efficiencies, and aligns with international best practices."
}
let obj2={
    "KeyFeatureHeading":"Key Benefits of Our Transfer Pricing  Services",
    "KeyFeatureFirstHeading":"Risk Management",
    "KeyFeatureFirstinfo":"Minimize tax audit and dispute risks.",
    "KeyFeatureSecondHeading":"Tax Efficiency",
    "KeyFeatureSecondinfo":"Maximize tax benefits, minimize liabilities.",
    "KeyFeatureThirdHeading":"Continuous Monitoring",
    "KeyFeatureThirdinfo":"Adjust strategies to evolving regulations.",
    "KeyFeatureFourthHeading":"Strategic Advisory",
    "KeyFeatureFourthinfo":"Align pricing with business goals effectively.",
    "BenefitHeading":"Benefits of Transfer Pricing.",
    "FirstBenefit":"Tax Compliance Assurance.",
    "SecondBenefit":"Risk Mitigation Strategy.",
    "ThirdBenefit":"Cost Efficiency Optimization.",
    "FourthBenefit":"Strategic Business Alignment.",
    "secondImagepath":"images/TransferPricing2.png"
}
return (
<div>
    <Header/>
    <ServicesMain infoMain={obj} />
    <ServicesBenefits infoBenefits={obj2} />
    <ScrollToTop/>
    <Footer/>
</div>
)

}

export default TransfePricing