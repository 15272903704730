import React from 'react'
import ServicesVedioBanner from '../Components/ServicesVedioBanner'
import Header from '../Components/Header'
import ServicesMainComponent from '../Components/ServicesMainComponent'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const Services = () => {
  return (
    <div>
        <Header/>
        <ServicesVedioBanner/>
        <ServicesMainComponent/>
        <ScrollToTop/>
        <Footer/>
    </div>
  )
}

export default Services