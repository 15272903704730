import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Correct path for Swiper styles
import 'swiper/css/effect-fade'; // Import effect-fade styles
import { Autoplay, Keyboard, EffectFade } from 'swiper/modules';
const HomeReviews = () => {
   
    
      return (
        <section className="bg-gradient-solitude-blue-transparent pt-0">
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-4 col-lg-2 col-sm-3 xs-mb-25px">
                <Swiper
                modules={[Autoplay, Keyboard, EffectFade]}
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                keyboard={{
                  enabled: true,
                  onlyInViewport: true,
                }}
                effect="fade"
              >
                <SwiperSlide>
                  <img style={{ height: '100%', width: '100%' }} src="../images/Kunal.png" alt="Kunal" />
                </SwiperSlide>
                <SwiperSlide>
                  <img style={{ height: '100%', width: '100%' }} src="../images/Nitin.png" alt="Nitin" />
                </SwiperSlide>
                <SwiperSlide>
                  <img style={{ height: '100%', width: '100%' }} src="../images/Saksham.png" alt="Saksham" />
                  </SwiperSlide> 
                </Swiper>
                </div>
                <div className="col-lg-5 col-xl-4 col-md-6 col-sm-7 text-center text-sm-start">
                    <h5 className="alt-font text-dark-gray lh-40 fw-500 ls-minus-1px mb-0 ms-10px lg-ms-0">
                        Trusted by <span className="fw-700 text-base-color">1,000+</span> happy customers.
                    </h5>
                </div>
                <div className="col-xl-2 offset-xl-2 col-lg-3 col-sm-4 md-mt-35px text-center text-lg-start">
                    <h2 className="alt-font text-dark-gray fw-700 ls-minus-3px mb-5px">100+</h2>
                    <span className="text-dark-gray fw-500 lh-24 d-inline-block w-90 lg-w-80 sm-w-100">
                        Testimonials
                    </span>
                </div>
                <div className="col-lg-2 col-sm-4 ps-40px md-ps-15px md-mt-35px text-center text-lg-start">
                    <h2 className="alt-font text-dark-gray fw-700 ls-minus-3px mb-5px">4.9</h2>
                    <div className="review-star-icon fs-17 lh-20 d-block">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                    </div>
                    <span className="text-dark-gray fw-500">Rating</span>
                </div>
            </div>

            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center mt-4 sm-mt-40px">
                <div className="col testimonials-style-01 md-mb-30px">
                    <div className="position-relative bg-white testimonial-arrow ps-50px pe-50px pt-30px pb-30px lg-p-30px border-radius-10px box-shadow-quadruple-large">
                        <span>Got my GST and audit work done with SSGA & Co. Highly recommended.</span>
                    </div>
                    <div className="mt-10px pt-20px pb-20px ps-15px pe-15px">
                        <img src="../images/Kunal.png" className="w-80px h-80px rounded-circle me-15px" alt="" />
                        <div className="d-inline-block align-middle lh-20">
                            <div className="fw-500 text-dark-gray alt-font mb-5px">Kunal Arora</div>
                        </div>
                    </div>
                </div>

                <div className="col testimonials-style-01 md-mb-30px">
                    <div className="position-relative bg-white testimonial-arrow ps-50px pe-50px pt-30px pb-30px lg-p-30px border-radius-10px box-shadow-quadruple-large">
                        <span>Good quality services and due care of clients. Best services with rational advice.</span>
                    </div>
                    <div className="mt-10px pt-20px pb-20px ps-15px pe-15px">
                        <img src="../images/Nitin.png" className="w-80px h-80px rounded-circle me-15px" alt="" />
                        <div className="d-inline-block align-middle lh-20">
                            <div className="fw-500 text-dark-gray alt-font mb-5px">Nitin Kumar</div>
                        </div>
                    </div>
                </div>

                <div className="col testimonials-style-01">
                    <div className="position-relative bg-white testimonial-arrow ps-50px pe-50px pt-30px pb-30px lg-p-30px border-radius-10px box-shadow-quadruple-large">
                        <span>Professional team of chartered accountants helping by filing returns on time.</span>
                    </div>
                    <div className="mt-10px pt-20px pb-20px ps-15px pe-15px">
                        <img src="../images/Saksham.png" className="w-80px h-80px rounded-circle me-15px" alt="" />
                        <div className="d-inline-block align-middle lh-20">
                            <div className="fw-500 text-dark-gray alt-font mb-5px">Saksham Goyal</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        )
}

export default HomeReviews