import React from 'react'
import Atropos from 'atropos/react';
import 'atropos/css';
const ServicesBenefits = (props) => {
    return (
        <div>

            <div>
                <section className="bg-solitude-blue">
                    <div className="container">
                        <div className="row justify-content-center mb-4">
                            <div className="col-xl-8 col-lg-9 col-md-10 text-center">
                                <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px sm-mb-20px" >
                                    {props.infoBenefits.KeyFeatureHeading}</h3>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center" >
                            <div className="col icon-with-text-style-04 transition-inner-all md-mb-30px">
                                <div className="feature-box hover-box transition dark-hover bg-white border-radius-6px pt-17 pb-17 ps-14 pe-14 last-paragraph-no-margin box-shadow-quadruple-large box-shadow-hover overflow-hidden">
                                    <div className="feature-box-icon">
                                        <i className="ti-bar-chart text-base-color icon-extra-large text-light-opacity mb-20px" />
                                    </div>
                                    <div className="feature-box-content">
                                        <span className="d-inline-block alt-font text-dark-gray fw-500 fs-18 mb-5px">{props.infoBenefits.KeyFeatureFirstHeading}</span>
                                        <p className="text-light-opacity">{props.infoBenefits.KeyFeatureFirstinfo}</p>
                                    </div>
                                    <div className="feature-box-overlay bg-base-color" />
                                </div>
                            </div>
                            <div className="col icon-with-text-style-04 transition-inner-all md-mb-30px">
                                <div className="feature-box hover-box transition dark-hover bg-white border-radius-6px pt-17 pb-17 ps-14 pe-14 last-paragraph-no-margin box-shadow-quadruple-large box-shadow-hover overflow-hidden">
                                    <div className="feature-box-icon">
                                        <i className="ti-time text-base-color icon-extra-large text-light-opacity mb-20px" />
                                    </div>
                                    <div className="feature-box-content">
                                    <span className="d-inline-block alt-font text-dark-gray fw-500 fs-18 mb-5px">{props.infoBenefits.KeyFeatureSecondHeading}</span>
                                    <p className="text-light-opacity">{props.infoBenefits.KeyFeatureSecondinfo}</p>
                                    </div>
                                    <div className="feature-box-overlay bg-base-color" />
                                </div>
                            </div>
                            <div className="col icon-with-text-style-04 transition-inner-all xs-mb-30px">
                                <div className="feature-box hover-box transition dark-hover bg-white border-radius-6px pt-17 pb-17 ps-14 pe-14 last-paragraph-no-margin box-shadow-quadruple-large box-shadow-hover overflow-hidden">
                                    <div className="feature-box-icon">
                                        <i className="ti-user text-base-color icon-extra-large text-light-opacity mb-20px" />
                                    </div>
                                    <div className="feature-box-content">
                                    <span className="d-inline-block alt-font text-dark-gray fw-500 fs-18 mb-5px">{props.infoBenefits.KeyFeatureThirdHeading}</span>
                                    <p className="text-light-opacity">{props.infoBenefits.KeyFeatureThirdinfo}</p>
                                    </div>
                                    <div className="feature-box-overlay bg-base-color" />
                                </div>
                            </div>
                            <div className="col icon-with-text-style-04 transition-inner-all">
                                <div className="feature-box hover-box transition dark-hover bg-white border-radius-6px pt-17 pb-17 ps-14 pe-14 last-paragraph-no-margin box-shadow-quadruple-large box-shadow-hover overflow-hidden">
                                    <div className="feature-box-icon">
                                        <i className="ti-bookmark-alt text-base-color icon-extra-large text-light-opacity mb-20px" />
                                    </div>
                                    <div className="feature-box-content">
                                    <span className="d-inline-block alt-font text-dark-gray fw-500 fs-18 mb-5px">{props.infoBenefits.KeyFeatureFourthHeading}</span>
                                    <p className="text-light-opacity">{props.infoBenefits.KeyFeatureFourthinfo}</p>
                                    </div>
                                    <div className="feature-box-overlay bg-base-color" />
                                </div>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-lg-3 row-cols-sm-2 align-items-center justify-content-center mt-6 xs-mt-8" >
                            <div className="col border-end xs-border-end-0 border-color-transparent-dark-very-light md-mb-35px">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-shrink-0 me-25px sm-me-15px">
                                        <h2 className="mb-0 text-dark-gray fw-700 ls-minus-2px">4.98</h2>
                                    </div>
                                    <div className="text-dark-gray">
                                        <div className="fs-14 lh-28">
                                            <i className="fa-solid fa-star" /><i className="fa-solid fa-star" /><i className="fa-solid fa-star" /><i className="fa-solid fa-star" /><i className="fa-solid fa-star" />
                                        </div>
                                        <span className="fs-17 lh-26 d-block fw-500">Rating</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col border-end md-border-end-0 border-color-transparent-dark-very-light md-mb-35px">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-shrink-0 me-25px sm-me-15px">
                                        <h2 className="mb-0 text-dark-gray fw-700 ls-minus-2px">98<sup className="fs-30">%</sup></h2>
                                    </div>
                                    <div className="text-dark-gray">
                                        <span className="fs-17 lh-26 d-block fw-500">Genuine client's <br />positive feedback.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-shrink-0 me-25px sm-me-15px">
                                        <h2 className="mb-0 text-dark-gray fw-700 ls-minus-2px">1000<sup className="fs-30">+</sup></h2>
                                    </div>
                                    <div className="text-dark-gray">
                                        <span className="fs-17 lh-26 d-block fw-500">Clients all <br />over world.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row align-items-center justify-content-center mb-7 sm-mb-40px">
                            <div className="col-xl-5 col-lg-6 col-md-10 md-mb-50px" >
                                <span className="bg-solitude-blue text-uppercase fs-13 ps-25px pe-25px alt-font fw-600 text-base-color lh-40 sm-lh-55 border-radius-100px d-inline-block mb-25px">Services
                                    benefits</span>
                                <h3 className="fw-600 text-dark-gray ls-minus-2px alt-font" >
                                    {props.infoBenefits.BenefitHeading}</h3>
                                <div className="icon-with-text-style-08 mb-10px">
                                    <div className="feature-box feature-box-left-icon-middle overflow-hidden">
                                        <div className="feature-box-icon feature-box-icon-rounded w-35px h-35px bg-solitude-blue rounded-circle me-10px">
                                            <i className="fa-solid fa-check fs-14 text-base-color" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">{props.infoBenefits.FirstBenefit}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-with-text-style-08 mb-10px">
                                    <div className="feature-box feature-box-left-icon-middle overflow-hidden">
                                        <div className="feature-box-icon feature-box-icon-rounded w-35px h-35px bg-solitude-blue rounded-circle me-10px">
                                            <i className="fa-solid fa-check fs-14 text-base-color" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">{props.infoBenefits.SecondBenefit}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-with-text-style-08 mb-10px">
                                    <div className="feature-box feature-box-left-icon-middle overflow-hidden">
                                        <div className="feature-box-icon feature-box-icon-rounded w-35px h-35px bg-solitude-blue rounded-circle me-10px">
                                            <i className="fa-solid fa-check fs-14 text-base-color" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">{props.infoBenefits.ThirdBenefit}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-with-text-style-08">
                                    <div className="feature-box feature-box-left-icon-middle overflow-hidden">
                                        <div className="feature-box-icon feature-box-icon-rounded w-35px h-35px bg-solitude-blue rounded-circle me-10px">
                                            <i className="fa-solid fa-check fs-14 text-base-color" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">{props.infoBenefits.FourthBenefit}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-xl-1 position-relative z-index-1" >
                                
                                    
                            <Atropos
                                activeOffset={40}
                                shadowScale={1.05}
                                highlight={false}
                            >        
                                            
                                                <img className="border-radius-6px" style={{ display: 'none', visibility: 'hidden' }} /> <img src={props.infoBenefits.secondImagepath} className="border-radius-6px" />
                             </Atropos>               
                                        
                                    
                                
                            </div>
                        </div>

                    </div>
                </section>
                <section className="half-section">
                    <div className="container">
                        <div className="row align-items-center text-center text-md-start">
                            <div className="col sm-mb-20px" >
                                <h4 className="alt-font text-dark-gray fw-600 ls-minus-2px m-0">Get a free personal business
                                    consultation?</h4>
                            </div>
                            <div className="col-12 col-md-auto" >
                                <a className="btn btn-large btn-dark-gray btn-box-shadow btn-rounded left-icon"><i className="feather icon-feather-mail" />Free consultation</a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </div>
    )
}

export default ServicesBenefits