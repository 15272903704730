import React from 'react'
import  {Link} from 'react-router-dom'
const ServicesMainComponent = () => {
    // Define the style for the progress bar
const progressBarStyle = {
    position: 'relative',
    width: '100%',
    height: '20px',
    backgroundColor: '#e0e0e0',
    borderRadius: '5px',
  };
  
  const textStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: '600',
  };
  
  const progressBarOptions = {
    strokeWidth: 1,
    trailWidth: 1,
    color: '#3498db', // Base color for progress
    trailColor: '#e0e0e0', // Background color
    text: {
      style: {
        color: '#ffffff', // Text color
        fontSize: '12px',
        fontWeight: '600',
      },
    },
  };
    return (
        <div>
            <div>
                <section className="big-section" id="down-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 md-mb-14 sm-mb-16 xs-mb-22 position-relative">
                                <div className="text-end w-70 xs-w-85 ms-auto" >
                                    <img src="../images/Audit.png"  className="border-radius-5px" />
                                </div>
                                <div className="w-60 xs-w-75 overflow-hidden position-absolute left-15px bottom-minus-50px" >
                                    <img src="../images/Accounting.png"  className="border-radius-5px box-shadow-quadruple-large" />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 text-center text-lg-start">
                                <span className="bg-solitude-blue text-uppercase fs-13 ps-25px pe-25px alt-font fw-600 text-base-color lh-40 sm-lh-55 border-radius-100px d-inline-block mb-25px" data-anime="{ &quot;translateY&quot;: [30, 0], &quot;opacity&quot;: [0,1], &quot;delay&quot;: 200, &quot;staggervalue&quot;: 100, &quot;easing&quot;: &quot;easeOutQuad&quot; }">31
                                    Years highly expertise</span>
                                <h3 className="alt-font fw-600 text-dark-gray ls-minus-2px" >
                                    Receive Expert Guidance and Achieve Comprehensive Results</h3>
                                <div className="progress-bar-style-01 mt-18 md-mt-12 sm-mt-70px" >
                                    <div className="progress mb-15 md-mb-70px bg-extra-medium-gray">
                                        <div className="progress-bar-title d-inline-block text-dark-gray fs-18 fw-500">Tax Planning &amp;
                                            Preparation
                                        </div>
                                        <div className="progress-bar bg-base-color main1" role="progressbar" aria-valuenow={80} aria-valuemin={0} aria-valuemax={100} aria-label="planning">
                                            <span className="progress-bar-percent text-center bg-dark-gray fs-12 lh-14 fw-600 text-white">80%</span>
                                        </div>
                                    </div>
                                    <div className="progress mb-15 md-mb-70px bg-extra-medium-gray">
                                        <div className="progress-bar-title d-inline-block text-dark-gray fs-18 fw-500">Business
                                            Consulting
                                        </div>
                                        <div className="progress-bar bg-base-color main2" role="progressbar" aria-valuenow={87} aria-valuemin={0} aria-valuemax={100} aria-label="research">
                                            <span className="progress-bar-percent text-center bg-dark-gray fs-12 lh-14 fw-600 text-white">87%</span>
                                        </div>
                                    </div>
                                    <div className="progress bg-extra-medium-gray">
                                        <div className="progress-bar-title d-inline-block text-dark-gray fs-18 fw-500">Audit &amp; Assurance
                                        </div>
                                        <div className="progress-bar bg-base-color main3" role="progressbar" aria-valuenow={93} aria-valuemin={0} aria-valuemax={100} aria-label="campaign">
                                            <span className="progress-bar-percent text-center bg-dark-gray fs-12 lh-14 fw-600 text-white">93%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-solitude-blue">
                    <div className="container">
                        <div className="row align-items-center justify-content-center text-center text-lg-start mb-6">
                            <div className="col-xl-6 col-lg-7 col-md-9 md-mb-30px sm-mb-20px">
                                <h3 className="alt-font fw-600 text-dark-gray m-0 ls-minus-2px" >
                                    Professional and highly dedicated services.</h3>
                            </div>
                            <div className="col-xl-5 offset-xl-1 col-lg-5 col-md-9 last-paragraph-no-margin">
                                <p className="w-90 lg-w-100" >
                                    At SSGA &amp; CO, we pride ourselves on delivering professional and highly dedicated services
                                    to our clients. Our commitment to excellence and personalized approach ensures that you receive
                                    the highest standard of care and expertise in all aspects of our services.</p>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center" >
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box mb-30px border-radius-5px overflow-hidden">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/Transfer.png"  />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link to={'/TransferPricing'} className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link to={'/TransferPricing'}  className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Transfer
                                            Pricing</Link>
                                        <p style={{ textAlign: 'justify' }}>It refers to the rules and methods for pricing transactions
                                            between enterprises under common ownership, ensuring compliance and fairness in
                                            intercompany dealings.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box mb-30px border-radius-5px overflow-hidden">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/Tax litigation.jpg"  />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link to={'/Taxlitigation'} className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link to={'/Taxlitigation'}   className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Tax
                                            Litigation</Link>
                                        <p>We assist clients with tax-related disputes and litigation, providing expert guidance to
                                            navigate complex legal challenges and resolve issues effectively.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box mb-30px border-radius-5px overflow-hidden">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/Service4.jpg"  />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link to={'/RegistrationComplinece'} className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link to={'/RegistrationComplinece'}  className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Registration
                                            and Compliances </Link>
                                        <p>"We help reduce the tax imposed by governments on individuals and businesses based on
                                            their income and profits earned during a taxable period.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box mb-30px border-radius-5px overflow-hidden">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/Service5.jpg"  />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link to={'/Startup'} className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link to={'/Startup'}   className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Start
                                            Up's</Link>
                                        <p style={{ textAlign: 'justify' }}>We provide Guidance and assistance with choosing the right
                                            business structure based on the startup's goals and regulatory&nbsp;requirements.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box mb-30px border-radius-5px overflow-hidden">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/Trade.jpg" />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link to={'/TradeMark'} className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link to={'/TradeMark'}  className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Trademark</Link>
                                        <p style={{ textAlign: 'justify' }}>We offers Trademark facility which is a indicator used by
                                            Businesses to distinguish their products &amp; services from others in the&nbsp;marketplace.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box mb-30px border-radius-5px overflow-hidden">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/Income.jpg" />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link to={'/IncomeTax'} className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link to={'/IncomeTax'}   className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Income
                                            Tax</Link>
                                        <p>We help in reducing the Tax imposed by govts on individuals &amp; businesses based on their
                                            income &amp; profits earned during a&nbsp;taxable&nbsp;period.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box mb-30px border-radius-5px overflow-hidden">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/NGO.jpg" />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link   className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link   className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Ngo
                                            Services</Link>
                                        <p style={{ textAlign: 'justify' }}>We provide comprehensive financial and compliance support
                                            tailored for non-profit organizations, ensuring regulatory adherence while maximizing
                                            operational efficiency and financial health.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box mb-30px border-radius-5px overflow-hidden">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/Labour.png" />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link   className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link   className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Labour
                                            Compliances</Link>
                                        <p>Guiding businesses to maintain compliance with labor laws and regulations, mitigating
                                            legal risks, and ensuring adherence to employment standards and safety regulations.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box mb-30px border-radius-5px overflow-hidden">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/Contract.png" />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link   className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link   className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Contract
                                            Compliances</Link>
                                        <p>Managing and overseeing compliance with contractual obligations to mitigate risks
                                            effectively, ensuring contractual obligations are met comprehensively.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box border-radius-5px overflow-hidden md-mb-30px">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/Audit.jpg" />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link   className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link   className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Audit &amp;
                                            Assurance </Link>
                                        <p style={{ textAlign: 'justify' }}>We ensure transparency and regulatory compliance through
                                            meticulous audit and assurance services, giving stakeholders confidence in the
                                            integrity.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box border-radius-5px overflow-hidden sm-mb-30px">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/Special.jpg" />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link   className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link   className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Specialised
                                            Services</Link>
                                        <p style={{ textAlign: 'justify' }}>Tailoring comprehensive financial and advisory solutions to
                                            meet unique client needs effectively, leveraging expertise across diverse industries and
                                            business scenarios.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="box-shadow-quadruple-large services-box-style-01 hover-box border-radius-5px overflow-hidden">
                                    <div className="position-relative box-image">
                                        <img style={{ height: 300 }} src="../images/business.jpg" />
                                        <div className="box-overlay bg-dark-gray" />
                                        <Link   className="d-flex justify-content-center align-items-center mx-auto icon-box absolute-middle-center z-index-1 w-60px h-60px rounded-circle box-shadow-quadruple-large border border-2 border-color-white"><i className="feather icon-feather-arrow-right text-white icon-extra-medium" /></Link>
                                    </div>
                                    <div className="p-10 bg-white last-paragraph-no-margin text-center">
                                        <Link   className="d-inline-block fs-18 alt-font fw-500 text-dark-gray mb-5px">Business
                                            Compliances</Link>
                                        <p>Navigating businesses through a complex landscape of regulatory obligations, ensuring
                                            full compliance with local and international laws.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </div>
    )
}

export default ServicesMainComponent