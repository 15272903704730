import React from 'react'
import ServicesMain from '../Components/ServicesMain'
import ServicesBenefits from '../Components/ServicesBenefits'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const IncomeTax = () => {
  let obj={
    "Category":"Income Tax",
    "firstImagepath":"images/incometax.png",
    "ServiceHeading":"Expert Income Tax Solutions.",
    "ServiceIntro":"At SSGA & CO.,we specialize in providing comprehensive income tax solutions that ensure compliance and optimize tax efficiencies. With over 31 years of experience, our dedicated team of Chartered Accountants is committed to helping individuals and businesses navigate the complexities of income tax regulations."
}
let obj2={
    "KeyFeatureHeading":"Key Benefits of Our Income Tax Services",
    "KeyFeatureFirstHeading":"Tax Planning and Advisory",
    "KeyFeatureFirstinfo":"We offer strategic tax planning and advisory services to help you minimize your tax liabilities and maximize your savings.",
    "KeyFeatureSecondHeading":"Tax Compliance",
    "KeyFeatureSecondinfo":"Ensuring compliance with all income tax regulations is crucial. We handle the preparation and filing of your income tax returns, ensuring accuracy.",
    "KeyFeatureThirdHeading":"Audit Support",
    "KeyFeatureThirdinfo":"In the event of a tax audit, our experts provide comprehensive support, representing you before tax authorities and ensuring that your interests are protected.",
    "KeyFeatureFourthHeading":"Tax Dispute Resolution",
    "KeyFeatureFourthinfo":"We assist in resolving tax disputes with authorities through negotiation, mediation, and, if necessary, litigation, aiming for the best possible outcome for our clients.",
    "BenefitHeading":"Key Benefits of Income Tax.",
    "FirstBenefit":"Optimized Tax Savings.",
    "SecondBenefit":"Expert Representation.",
    "ThirdBenefit":"Personalized Solutions.",
    "FourthBenefit":"Regulatory Compliance.",
    "secondImagepath":"images/incometax2.jpg"
}
return (
<div>
    <Header/>
    <ServicesMain infoMain={obj} />
    <ServicesBenefits infoBenefits={obj2} />
    <ScrollToTop/>
    <Footer/>
</div>
)

}

export default IncomeTax