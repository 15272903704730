import React from 'react'
import ServicesMain from '../Components/ServicesMain'
import ServicesBenefits from '../Components/ServicesBenefits'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ScrollToTop from '../Components/ScrollToTop'

const Taxlitigation = () => {
    let obj={
        "Category":"Tax Litigation",
        "firstImagepath":"images/taxlitigation.png",
        "ServiceHeading":"Expert Tax Litigation Solutions.",
        "ServiceIntro":"At SSGA & CO, we are dedicated to providing expert solutions in tax litigation. With over 10 years of experience, our team is committed to achieving positive outcomes for our clients. We navigate complex tax disputes, ensuring compliance with legal regulations and striving for fair and favorable resolutions."
    }
    let obj2={
        "KeyFeatureHeading":"Key Benefits of Our Tax Litigation Services",
        "KeyFeatureFirstHeading":"Legal Dispute Resolution",
        "KeyFeatureFirstinfo":"Tax litigation involves resolving disputes between taxpayers and tax authorities",
        "KeyFeatureSecondHeading":"Risk and Compliance",
        "KeyFeatureSecondinfo":"Effective management of tax disputes requires ensuring compliance with procedural requirements.",
        "KeyFeatureThirdHeading":"Legal Representation",
        "KeyFeatureThirdinfo":" Taxpayers typically engage specialized tax lawyers or firms to advocate on their behalf",
        "KeyFeatureFourthHeading":"Complexity",
        "KeyFeatureFourthinfo":"Cases often involve intricate tax laws, regulations, and interpretations, requiring specialized knowledge .",
        "BenefitHeading":"Benefits of Tax Litigation.",
        "FirstBenefit":"Resolution of Disputes.",
        "SecondBenefit":"Legal Expertise.",
        "ThirdBenefit":"Risk Management.",
        "FourthBenefit":"Compliance Assurance.",
        "secondImagepath":"images/TAX.jpg"
    }
  return (
    <div>
        <Header/>
        <ServicesMain infoMain={obj} />
        <ServicesBenefits infoBenefits={obj2} />
        <ScrollToTop/>
        <Footer/>
    </div>
  )
}

export default Taxlitigation