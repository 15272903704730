import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const Header = () => {
    const [isSticky, setSticky] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [toggle2, setToggle2] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleToggle = () => {
        setToggle(prevToggle => !prevToggle);
    };
    const handleToggle2 = () => {
        setToggle2(prevToggle2 => !prevToggle2);
    };

    return (
        <div className={toggle ? 'is-touchable no-animation navbar-collapse-show' : 'is-touchable no-animation'}>
            <header className={isSticky ? "sticky sticky-active" : ""}>
                <nav className="navbar navbar-expand-lg header-transparent bg-transparent header-reverse" data-header-hover="light">
                    <div className="container-fluid">
                        <div className="col-sm-5 col-xxl-3 col-lg-3 me-lg-0 me-auto">
                            <a className="navbar-brand" href="#">

                                <img src="./../images/SSGA&CO White.png" className="default-logo" alt="Single Logo" />
                                <img className="alt-logo" style={{ display: 'none', visibility: 'hidden' }} alt="Alternate Logo" />
                                <img src="./../images/SSGA&CO Blue.png" className="alt-logo" alt="Main Logo" />
                                <img src="./../images/SSGA&CO Blue.png" className="mobile-logo" alt="Mobile Logo" />
                            </a>
                        </div>
                        <div className="col-auto menu-order position-static">
                            <button onClick={handleToggle} className="navbar-toggler float-start" type="button" aria-controls="navbarNav" aria-label="Toggle navigation">
                                <span className="navbar-toggler-line" />
                                <span className="navbar-toggler-line" />
                                <span className="navbar-toggler-line" />
                                <span className="navbar-toggler-line" />
                            </button>
                            <div className={toggle ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarNav">
                                <ul className="navbar-nav alt-font">
                                    <li className="nav-item"><Link to={'/'} className="nav-link arimo-bold">HOME</Link></li>
                                    <li className="nav-item"><Link to={'/Aboutus'} className="nav-link arimo-bold">About Us</Link></li>
                                    <li className="nav-item dropdown dropdown-with-icon-style02">
                                        <Link to={'/Services'} className="nav-link arimo-bold">SERVICES</Link>
                                        <i onClick={handleToggle2} className={toggle2 ? "fa-solid fa-angle-down dropdown-toggle show" : "fa-solid fa-angle-down dropdown-toggle"} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                        <ul className={toggle2 ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="navbarDropdownMenuLink">
                                            <li><Link to={'/Startup'} ><i className="bi bi-briefcase" />Start Up</Link></li>
                                            <li><Link to={'/Taxlitigation'} ><i className="bi bi-send-check" />Tax Litigation Supply</Link></li>
                                            <li><Link to={'/TradeMark'} ><i className="bi bi-bar-chart-line" />Trademark and IP</Link></li>
                                            <li><Link to={'/RegistrationComplinece'} ><i className="bi bi-briefcase" />Registrations and Compliances</Link></li>
                                            <li><Link to={'/IncomeTax'} ><i className="bi bi-clipboard-data" />Income Tax</Link></li>
                                            <li><Link to={'/TransferPricing'}><i className="bi bi-globe" />Transfer Pricing</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><Link to={'/Contact'} className="nav-link arimo-bold">CONTACT</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-auto col-xxl-3 col-lg-2 text-end d-none d-sm-flex">
                            <div className="header-icon">
                                <div className="d-none d-xxl-inline-block me-25px xxl-me-10px">
                                    <div className="alt-font fs-15 xl-fs-13 widget-text fw-500">
                                        <span className="w-35px h-35px bg-base-color d-inline-block lh-36 me-10px border-radius-100px">
                                            <i className="feather icon-feather-phone me-10px" />
                                        </span>
                                        <a href="tel:+01149789351" className="widget-text text-white-hover">+011-49789351</a>
                                    </div>
                                </div>
                                <div className="header-button">
                                    <Link to={'/Contact'} className="btn btn-very-small btn-transparent-white-light btn-rounded">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default Header