import React from 'react'
import { Link } from 'react-router-dom'

const Homevideo = () => {
  return (
    

    <div>
  <section className="section-dark p-0 bg-dark-gray">
    <div className="full-screen md-h-600px sm-h-500px ipad-top-space-margin ">
      <div className="swiper-wrapper">
        <div className="overflow-hidden">
          <video height="100%" width="100%" className="main" autoPlay loop muted>
            <source src="./vedio/5311282-hd_1920_1080_25fps.mp4" type="video/mp4" />
          </video>
          <div className="cover-background position-absolute top-0 start-0 w-100 h-100">
            <div className="opacity-light bg-gradient-sherpa-blue-black" />
            <div className="container h-100">
              <div className="row align-items-center h-100">
                <div className="main-position col-xl-7 col-lg-8 col-md-10 position-relative text-white text-center text-md-start" >
                  
                    <span className="fs-20 opacity-6  sm-mb-15px d-inline-block fw-300">WELCOME
                      TO OUR</span>
                  
                  <h1 className="alt-font w-90 xl-w-100 text-shadow-double-large ls-minus-2px main-h1" >
                    <span className='fw-600' >SSGA &amp; CO. </span><br />
                    <span >India's Leading CA Firm</span>
                  </h1>
                  <Link to={'/Contact'} className="btn btn-extra-large btn-rounded with-rounded btn-base-color btn-box-shadow box-shadow-extra-large  sm-mt-0">Get
                    started now<span className="bg-white text-base-color"><i className="fas fa-arrow-right" /></span></Link>
                </div>
              </div>
              <div className="position-absolute bottom-minus-45px" >
                <span className="alt-font number text-base-color opacity-3 fs-190 fw-600 ls-minus-5px">01</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</div>

  )
}

export default Homevideo