import React from 'react'
import {BrowserRouter,Routes,Route } from 'react-router-dom'
import Home from './Pages/Home'
import Contact from './Pages/Contact'
import Aboutus from './Pages/Aboutus'
import Services from './Pages/Services'
import './css/vendors.min.css'
import './css/icon.min.css'
import './css/style.min.css'
import './css/responsive.min.css'
import './demos/business/business.css'
import Taxlitigation from './Pages/Taxlitigation'
import TransfePricing from './Pages/TransfePricing'
import TradeMark from './Pages/TradeMark'
import RegistrationComplinece from './Pages/RegistrationComplinece'
import Startup from './Pages/Startup'
import IncomeTax from './Pages/IncomeTax'
import Error from './Pages/Error'
const App = () => {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}  />
        <Route path='/Services' element={<Services/>}  />
        <Route path='/Aboutus' element={<Aboutus/>}  />
        <Route path='/Contact' element={<Contact/>}  />
        <Route path='/Taxlitigation' element={<Taxlitigation/>}  />
        <Route path='/TransferPricing' element={<TransfePricing/>}  />
        <Route path='/TradeMark' element={<TradeMark/>}  />
        <Route path='/RegistrationComplinece' element={<RegistrationComplinece/>}  />
        <Route path='/Startup' element={<Startup/>}  />
        <Route path='/IncomeTax' element={<IncomeTax/>}  />
        <Route path='*' element={<Error/>}  />
      </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App