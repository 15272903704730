import React from 'react'

const ContactForm = () => {
    return (
        <div>
            <section id="down-section" className="overflow-hidden">
                <div className="container">
                    <div className="row g-0 justify-content-center">
                        <div className="col-lg-3 col-md-6" >
                            <div className="h-100 sm-h-500px xs-h-400px cover-background" style={{ backgroundImage: 'url(../images/Contactus.jpg)' }} />
                        </div>
                        <div className="col-lg-4 col-md-6" >
                            <div className="bg-base-color p-18 lg-p-15 h-100">
                                <span className="text-white text-decoration-line-bottom mb-10px d-inline-block">Address </span>
                                <p className="text-white ">
                                    Delhi Branch Office 1 :
                                    720, PP Trade Centre, Netaji Subhash Palace, Pitampura, N.D -110034
                                </p>
                                <p className="text-white ">
                                    Delhi Branch Office 2:
                                    D-2/192, Sector-11, Rohini, New Delhi -110085 +91 99538 56288
                                </p>
                                <span className="text-white text-decoration-line-bottom mb-10px d-inline-block">Toll free customer
                                    care?</span>
                                <p><a href="tel:+01149789351" className="text-white">+011-49789351</a></p>
                                <p><a href="tel:+01149099770" className="text-white">+011-49099770</a></p>
                                <span className="text-white text-decoration-line-bottom mb-10px d-inline-block">Need live
                                    support?</span>
                                <a href="mailto:ssgaco7@gmail.com" className="text-white  d-block"><span className="__cf_email__">ssgaco7@gmail.com</span></a>
                            </div>
                        </div>
                        <div className="col-lg-4 offset-lg-1">
                            <div className="pt-5 md-pt-45px contact-form-style-01" >
                                <h4 className="d-inline-block alt-font fw-600 text-dark-gray ls-minus-1px mb-30px">Feel free to
                                    reach via contact us form.</h4>
                                <form>
                                    <div className="position-relative form-group mb-20px">
                                        <span className="form-icon"><i className="bi bi-emoji-smile" /></span>
                                        <input type="text" name="name" className="form-control required" placeholder="Your name*" />
                                    </div>
                                    <div className="position-relative form-group mb-20px">
                                        <span className="form-icon"><i className="bi bi-envelope" /></span>
                                        <input type="email" name="email" className="form-control required" placeholder="Your email address*" />
                                    </div>
                                    <div className="position-relative form-group form-textarea">
                                        <span className="form-icon"><i className="bi bi-chat-square-dots" /></span>
                                        <textarea placeholder="Your message" name="comment" className="form-control" rows={3} defaultValue={""} />
                                        {/* <input type="hidden" name="redirect" defaultValue /> */}
                                        <button className="btn btn-small btn-round-edge btn-dark-gray btn-box-shadow mt-20px m-auto submit" type="submit">Send message</button>
                                        <div className="form-results mt-20px d-none" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default ContactForm