import React from 'react'

const ServicesMain = (props) => {
    
    return (
    <div>
<div>
  <section className="page-title-big-typography bg-dark-gray ipad-top-space-margin" data-parallax-background-ratio="0.5" style={{backgroundImage: 'url(images/main.jpg)'}}>
    <div className="opacity-extra-medium bg-dark-slate-blue" />
    <div className="container">
      <div className="row align-items-center justify-content-center extra-small-screen">
        <div className="col-12 position-relative text-center page-title-extra-large">
          <h1 className="m-auto text-white text-shadow-double-large fw-500 ls-minus-3px xs-ls-minus-2px" >
            {props.infoMain.Category}</h1>
        </div>
        <div className="down-section text-center" >
          <a href="#down-section" aria-label="scroll down" className="section-link">
            <div className="d-flex justify-content-center align-items-center mx-auto rounded-circle fs-30 text-white">
              <i className="feather icon-feather-chevron-down" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
  <section id="down-section">
    <div className="container">
      <div className="row align-items-center justify-content-center" >
        <div className="col-lg-6 md-mb-40px" >
          <figure className="position-relative m-0">
            <img src={props.infoMain.firstImagepath} className="w-100 border-radius-5px" /> 
            <figcaption className="w-190px sm-w-180px xs-w-140px bg-white p-30px xs-p-15px border-radius-6px position-absolute bottom-30px left-30px xs-bottom-20px xs-left-15px overflow-hidden box-shadow-medium animation-float text-center" >
              <span className="alt-font fs-90 xs-fs-80 fw-700 text-white d-block position-relative z-index-1">10</span>
              <div className="alt-font fw-500 fs-20 xs-fs-18 d-block text-dark-gray lh-24 xs-lh-22 ls-minus-05px xs-mb-5px">
                Years of experience</div>
              <div className="h-160px w-160px border-radius-100 bg-base-color position-absolute left-minus-5px xs-left-minus-25px top-minus-60px sm-top-minus-80px xs-top-minus-100px z-index-0">
              </div>
            </figcaption>
          </figure>
        </div>
        <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-10 text-center text-lg-start" >
          <span className="bg-solitude-blue text-uppercase fs-13 ps-25px pe-25px alt-font fw-600 text-base-color lh-40 sm-lh-55 border-radius-100px d-inline-block mb-25px">Tax Litigation</span>
          <h3 className="fw-600 text-dark-gray mb-20px ls-minus-2px alt-font">{props.infoMain.ServiceHeading}</h3>
          <p className="w-95 md-w-100 mb-35px">{props.infoMain.ServiceIntro}</p>
          <div className="pt-20px pb-20px ps-30px pe-30px xs-p-15px border border-color-extra-medium-gray border-radius-6px mb-15px icon-with-text-style-08 w-90 lg-w-100">
            <div className="feature-box feature-box-left-icon-middle d-inline-flex align-middle">
              <div className="feature-box-icon me-10px">
                <i className="bi bi-people icon-medium text-base-color" />
              </div>
              <div className="feature-box-content">
                <span className="alt-font fw-600 text-dark-gray d-block lh-26">1k+ people trusting the
                  agency.</span>
              </div>
            </div>
          </div>
          <p className="fs-13 mb-0">We are excited for our work and how it <span className="text-dark-gray text-decoration-line-bottom">positively</span> impacts clients.</p>
        </div>
      </div>
    </div>
  </section>
</div>

    </div>
  )
}

export default ServicesMain