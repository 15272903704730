import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <div>
            <footer className="pt-5 pb-5 sm-pt-40px sm-pb-45px footer-dark bg-extra-medium-slate-blue">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-4 col-md-6 col-sm-10 last-paragraph-no-margin  text-center text-sm-start">
                            <a href="index.html" className="footer-logo mb-15px d-block d-lg-inline-block">
                                <img src="../images/SSGA&CO White.png"  />
                            </a>
                            <p className="w-90 sm-w-100 d-inline-block mb-15px">We provide best accounting services and advices for
                                your business by building long-lasting relationships with out clients.</p>
                        </div>
                        <div className="col-12 col-lg-2 col-md-6 col-sm-4 md-mb-50px sm-mb-30px ">
                            <span className="alt-font d-block text-white mb-5px">Resources</span>
                            <ul>
                                <li><Link to={'/'}>Home</Link></li>
                                <li><Link to={'/Aboutus'}>About us</Link></li>
                                <li><Link to={'/Services'}>Services</Link></li>
                                <li><Link to={'/Contact'}>Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-2 col-md-6 col-sm-4 md-mb-50px sm-mb-30px ">
                            <span className="alt-font d-block text-white mb-5px">Services</span>
                            <ul>
                                <li><Link to={'/RegistrationComplinece'} >Registration Compliance</Link></li>
                                <li><Link to={'/Startup'} >Start up</Link></li>
                                <li><Link to={'/TradeMark'} >Trademark</Link></li>
                                <li><Link to={'/Taxlitigation'} >Tax Litigation</Link></li>
                                <li><Link to={'/IncomeTax'} >Income Tax</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-4 xs-mb-30px last-paragraph-no-margin ">
                            <span className="alt-font d-block text-white mb-5px">Get in touch</span>
                            <p className="w-80 lg-w-100 md-w-70 sm-w-100 mb-10px">Registered Office : 720, PP Trade Centre, Netaji Subhash Palace, Pitampura, N.D -110034</p>
                            <div><i className="feather icon-feather-phone-call icon-very-small text-white me-10px" /><a href="tel:+01149789351" className="text-white">+011-49789351</a></div>
                            <div><i className="feather icon-feather-phone-call icon-very-small text-white me-10px" /><a href="tel:+919911695896" className="text-white">+91-9911695896</a></div>
                            <div><i className="feather icon-feather-mail icon-very-small text-white me-10px" /><a href="mailto:ssgaco7@gmail.com" className="text-white text-decoration-line-bottom"><span className="__cf_email__">ssgaco7@gmail.com</span></a></div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

    )
}

export default Footer